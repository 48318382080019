<template>
    <b-card>
        <!-- media -->
        <b-media no-body>
            <b-media-aside>
                <b-link>
                    <b-avatar ref="previewEl" rounded :src="optionsLocal.avatar" size="80">
                        <feather-icon v-if="!optionsLocal.avatar" icon="UserIcon" size="80" />
                    </b-avatar>
                </b-link>
                <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click.prevent="$refs.refInputEl.$el.click()"
                >
                    Upload
                </b-button>
                <b-form-file
                    ref="refInputEl"
                    v-model="profileFile"
                    accept=".jpg, .png, .jpeg"
                    :hidden="true"
                    plain
                    @change="previewAvatar"
                />
                <!--/ upload button -->

                <!-- reset -->
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                >
                    Reset
                </b-button>
                <!--/ reset -->
                <b-card-text>Allowed JPG, JPEG or PNG. Max size of 800kB</b-card-text>
            </b-media-body>
        </b-media>
        <!--/ media -->

        <!-- form -->
        <b-form class="mt-2">
            <b-row>
                <b-col sm="6">
                    <b-form-group label="Name" label-for="account-name">
                        <b-form-input v-model="optionsLocal.fullName" placeholder="Name" name="name" readonly />
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group label="Phone" label-for="account-phone">
                        <b-form-input
                            v-model="optionsLocal.phone"
                            name="phone"
                            placeholder="+90 5XX XXX XX XX"
                            readonly
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group label="E-mail" label-for="account-e-mail">
                        <b-form-input v-model="optionsLocal.email" name="email" placeholder="Email" readonly />
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group label="Department" label-for="account-department">
                        <b-form-input
                            v-model="optionsLocal.department"
                            name="department"
                            readonly
                            placeholder="Department Name"
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-2 mr-1"
                        @click.prevent="submitForm()"
                    >
                        Save changes
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        type="reset"
                        class="mt-2"
                        @click.prevent="resetForm"
                    >
                        Reset
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";

import axios from "@axios";

export default {
    components: {
        BButton,
        BForm,
        BFormFile,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BCardText,
        BMedia,
        BMediaAside,
        BMediaBody,
        BLink,
        BAvatar,
    },
    directives: {
        Ripple,
    },
    props: {
        generalData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
            profileFile: null,
        };
    },
    methods: {
        resetForm() {
            this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
        },
        submitForm() {
            const formData = new FormData();
            formData.append("file", this.profileFile);

            axios
                .post("/upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(response => {
                    if (response.status === 200) {
                        const userData = JSON.parse(localStorage.getItem("userData"));
                        userData.avatar = response.data.path;
                        localStorage.setItem("userData", JSON.stringify(userData));
                        window.location.reload();
                    }
                });
        },
        previewAvatar(e) {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.addEventListener(
                "load",
                () => {
                    this.optionsLocal.avatar = reader.result;
                },
                false,
            );

            if (file) {
                reader.readAsDataURL(file);
            }
        },
    },
    setup() {
        const refInputEl = ref(null);
        const previewEl = ref(null);

        return {
            refInputEl,
            previewEl,
        };
    },
};
</script>
